import axios from "axios"

export default {
    state: {
        admin_tovars: {}
    },
    getters: {
        admin_tovars: (state) => state.admin_tovars
    },
    mutations: {
        SET_ADMIN_TOVARS(state, playload) {
            state.admin_tovars = playload
        }
    },
    actions: {
        async GET__AdminTovars({commit}, filter) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/tovars`, {
                name: filter.name,
                company_id: filter.company_id
            })
                .then(response => { 
                    commit('SET_ADMIN_TOVARS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                })
        }
     }
}