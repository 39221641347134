import axios from "axios"

export default {
    state: {
        admin_users: {},
        admin_user: {
            user: {},
            company: {}
        },
        roles: [
            {role: 'ROLE_SALLER', name: 'Продавец'},
            {role: 'ROLE_CUSTOMER', name: 'Покупатель'},
        ],
        admin_users_offset: 0,
        admin_users_filter: {
            email: '',
            surname: ''
        }
    },
    getters: {
        admin_users: (state) => state.admin_users,
        admin_user: (state) => state.admin_user,
        roles: (state) => state.roles,
        admin_users_offset: (state) => state.admin_users_offset,
        admin_users_filter: (state) => state.admin_users_filter,

    },
    mutations: {
        SET_ADMIN_USERS(state, playload) {
            state.admin_users = playload
        },
        SET_ADMIN_USER(state, playload) {
            state.admin_user = playload
        },
        SET_ADMIN_USERS_OFFSET(state, playload) {
            state.admin_users_offset = playload
        },
        SET_ADMIN_USER_FILTER_FIELD(state, playload) {
            state.admin_users_filter[playload.field] = playload.value
        }
    },
    actions: {
        getAdminUsers({commit, state}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/admin/users/all`, {
                email: state.admin_users_filter.email,
                surname: state.admin_users_filter.surname,
                role: '',
                offset: state.admin_users_offset
            })
                .then(response => { 
                    commit('SET_ADMIN_USERS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        GET__AdminUser({commit}, user_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.get(`${process.env.VUE_APP_API_URL}/api/admin/users/`+user_id)
                .then(response => { 
                    commit('SET_ADMIN_USER', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        setAccessStatus({commit, dispatch}, data) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.patch(`${process.env.VUE_APP_API_URL}/api/admin/users/`+data.user_id, {
                status: data.status
            })
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    dispatch('GET__AdminUser', data.user_id)
                    dispatch('getAdminUsers')
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        }
    }
}