<template>
    <Teleport to="body">
        <div class="common-success" :class="{'error': success_window.is_error}" v-if="success_window.state">
            <div class="common-success--icon" v-if="!success_window.is_error">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none">
                    <path d="M8.38 12L10.79 14.42L15.62 9.58" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.75 2.45C11.44 1.86 12.57 1.86 13.27 2.45L14.85 3.81C15.15 4.07 15.71 4.28 16.11 4.28H17.81C18.87 4.28 19.74 5.15 19.74 6.21V7.91C19.74 8.3 19.95 8.87 20.21 9.17L21.57 10.75C22.16 11.44 22.16 12.57 21.57 13.27L20.21 14.85C19.95 15.15 19.74 15.71 19.74 16.11V17.81C19.74 18.87 18.87 19.74 17.81 19.74H16.11C15.72 19.74 15.15 19.95 14.85 20.21L13.27 21.57C12.58 22.16 11.45 22.16 10.75 21.57L9.17 20.21C8.87 19.95 8.31 19.74 7.91 19.74H6.18C5.12 19.74 4.25 18.87 4.25 17.81V16.1C4.25 15.71 4.04 15.15 3.79 14.85L2.44 13.26C1.86 12.57 1.86 11.45 2.44 10.76L3.79 9.17C4.04 8.87 4.25 8.31 4.25 7.92V6.2C4.25 5.14 5.12 4.27 6.18 4.27H7.91C8.3 4.27 8.87 4.06 9.17 3.8L10.75 2.45Z" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="common-success--icon" v-if="success_window.is_error">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none">
                    <path d="M14.9 2H9.10001C8.42001 2 7.46 2.4 6.98 2.88L2.88 6.98001C2.4 7.46001 2 8.42001 2 9.10001V14.9C2 15.58 2.4 16.54 2.88 17.02L6.98 21.12C7.46 21.6 8.42001 22 9.10001 22H14.9C15.58 22 16.54 21.6 17.02 21.12L21.12 17.02C21.6 16.54 22 15.58 22 14.9V9.10001C22 8.42001 21.6 7.46001 21.12 6.98001L17.02 2.88C16.54 2.4 15.58 2 14.9 2Z" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5 15.5L15.5 8.5" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.5 15.5L8.5 8.5" stroke="#fff" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="common-success--text">
                {{ success_window.text }}
            </div>
        </div>
    </Teleport>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'CommonSuccess',
    computed: {
        ...mapGetters(['success_window'])
    },
    watch: {
        success_window: function(newVal) {
            console.log(this.success_window)
            if(this.success_window.state) {
                setTimeout(() => {this.setSuccess({state: false, text: '', is_error: false})}, 5000)
            }
        }
    },
    methods: {
        ...mapActions(['setSuccess'])
    }
}
</script>

<style lang="scss" scoped>
    .error {
        background: rgb(238, 125, 125) !important;
    }
    .common-success {
        display: flex;
        position: fixed;
        top: 30px;
        right: 0px;
        width: 300px;
        height: fit-content;
        padding: 10px;
        flex-direction: row;
        background: rgba($color: rgb(109, 197, 109), $alpha: 1.0);
        align-items: center;
        align-content: center;
        gap: 10px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        animation: showModal .5s;
        z-index: 999;
        &--icon {
            display: flex;
            width: 48px;
            height: 48px;
        }
        &--text {
            display: flex;
            font-size: 16px;
            color: #fff;
        }
    }


    @keyframes showModal {
        from {
        /*visibility: hidden;
        opacity: 0;*/
        transform: translateX(100%);
        }
        to {
        /*visibility: visible;
        opacity: 1;*/
        transform: translateX(0%);
        }
    }
</style>