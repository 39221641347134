import axios from "axios"

export default {
    state: {
        admin_ref_tovars: {},
        admin_ref_tovar: {
            name: '',
            article: '',
            description: '',
            registration_certificate: '',
            photo_url: ''
        },
        admin_ref_filter: {
            name: '',
            article: ''
        }
    },
    getters: {
        admin_ref_tovars: (state) => state.admin_ref_tovars,
        admin_ref_tovar: (state) => state.admin_ref_tovar,
        admin_ref_filter: (state) => state.admin_ref_filter,
    },
    mutations: {
        SET_ADMIN_REF_TOVARS(state, playload) {
            state.admin_ref_tovars = playload
        },
        SET_ADMIN_REF_TOVAR(state, playload) {
            state.admin_ref_tovar = playload
        },
        SET_ADMIN_REF_TOVAR_FIELD(state, playload) {
            state.admin_ref_tovar[playload.field] = playload.value
        },
        SET_ADMIN_REF_FILTER(state, playload) {
            state.admin_ref_filter[playload.field] = playload.value
        }
    },
    actions: {
        getAdminRefTovars({commit, state}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/admin/ref_tovars/all`, {
                name: state.admin_ref_filter.name,
                article: state.admin_ref_filter.article,
                offset: 0
            })
                .then(response => { 
                    commit('SET_ADMIN_REF_TOVARS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        getAdminTovarById({commit}, id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.get(`${process.env.VUE_APP_API_URL}/api/admin/ref_tovars/get/` + id)
                .then(response => { 
                    commit('SET_ADMIN_REF_TOVAR', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        updateAdminTovarield({commit}, data) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/admin/ref_tovars/updateField`, {
                field: data.field,
                value: data.value,
                id: data.id
            })
                .then(response => { 
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        updateAdminTovarFile({commit, dispatch}, data) {
            commit('SET_PRELOADER', true)
            let formData = new FormData();
            formData.append('file', data.file)
            formData.append('id', data.id)

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/admin/ref_tovars/reg_cert`, formData)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    dispatch('getAdminTovarById', data.id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        updateAdminTovarPhoto({commit, dispatch}, data) {
            commit('SET_PRELOADER', true)
            let formData = new FormData();
            formData.append('photo', data.file)
            formData.append('id', data.id)

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/admin/ref_tovars/photo`, formData)
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    dispatch('getAdminTovarById', data.id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        }
    }
}