<template>
    <Teleport to="body">
        <div class="unauth" v-if="access_blocked_msg">
            <div class="unauth-message-bg"></div>
            <div class="unauth-message">
                <div class="unauth-message--title">Доступ в систему заблокирован!</div>
                <div class="unauth-message--text">
                    Свяжитесь с администратрами для уточнения причины блокировки!<br /><br />
                    <!--<div class="unauth-message--text--link" @click="$router.push({name: 'LoginView'})">Войдите</div>
                     или <div class="unauth-message--text--link" @click="$router.push({name: 'EnterView'})">зарегистрируйтесь</div> для продолжения работы в системе.-->
                </div>
                <div class="unauth-message--text">
                    <base-button @click="SET_ACCESS_BLOCKED_MESSAGE(false); $router.push({name: 'IndexDemoView'})">На главную</base-button>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import BaseButton from '../ui/buttons/BaseButton.vue'
import BaseLink from '../ui/links/BaseLink.vue'
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: { BaseLink, BaseButton },
    name: 'UserAccessBlocked',
    computed: {
        ...mapGetters(['access_blocked_msg'])
    },
    methods: {
        ...mapMutations(['SET_ACCESS_BLOCKED_MESSAGE'])
    }
}
</script>

<style lang="scss" scoped>
    .unauth {
        display: flex;
        position: fixed;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        left: 0px;
        top: 0px;
        justify-content: center;
        align-content: center;
        align-items: center;
        z-index: 9999;
    }
    .unauth-message-bg {
        display: flex;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.3);
        z-index: 9999;
    }
    .unauth-message {
        display: flex;
        position: fixed;
        width: 350px;
        height: fit-content;
        box-sizing: border-box;
        border-radius: 7px;
        background: #fff;
        z-index: 99999;
        margin-top: -200px;
        padding: 20px;
        flex-direction: column;
        gap: 25px;
        &--title {
            display: flex;
            font-size: 16px;
            font-weight: 500;
        }
        &--text {
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            
            &--link {
                display: inline;
                color: blue;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>