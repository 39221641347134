export default [
    {
        path: '/admin-panel',
        name: 'AdminPanel',
        component: function () {
          return import('../../views/admin/Admin.vue')
        },
        children: [
            {
                path: 'monitoring',
                name: 'AdminPanelMonitoring',
                component: function () {
                return import('../../views/admin/AdminPanelMonitoring.vue')
                },
            },
            {
                path: 'users',
                name: 'AdminPanelUsers',
                component: function () {
                return import('../../views/admin/AdminPanelUsers.vue')
                },
            },
            {
                path: 'companies',
                name: 'AdminPanelCompanies',
                component: function () {
                return import('../../views/admin/AdminPanelCompanies.vue')
                },
            },
            {
                path: 'refbooks',
                name: 'AdminPanelRefbooks',
                component: function () {
                return import('../../views/admin/AdminPanelRefbooks.vue')
                },
            },
            {
                path: 'tovars',
                name: 'AdminPanelTovars',
                component: function () {
                return import('../../views/admin/AdminPanelTovars.vue')
                },
            },
            {
                path: 'orders',
                name: 'AdminPanelOrders',
                component: function () {
                return import('../../views/admin/AdminPanelOrders.vue')
                },
            },
        ]
    },
]