import axios from "axios"
export default {
    state: {
        find_tovars: {},
        find_filters: {
            name: '',
            place: 0,
            place_name: '',
            date: '',
            offset: ''
        },
        find_tovars_count: 0,
        company_orders: {},
        find_offset: 0,
        find_limit: 12,
        company_contacts: {},
        buyer_contacts: {},
        order_statuses: [
            {id: 0, name: 'wait_accept_orders', value: 'Ожидает подтверждение продавца', color: 'orange'},
            {id: 1, name: 'accepted_orders', value: 'Продавец подтвердил бронь', color: 'green'},
            {id: 2, name: 'canceled_orders', value: 'Отказ брони товара', color: 'red'},
            {id: 3, name: 'successed_orders', value: 'Сделка завершена', color: 'green'},
            {id: 4, name: 'waiting_list', value: 'Лист ожидания', color: 'grey'},
        ],
        active_order_filters: {
            all_orders: true,
            wait_accept_orders: false,
            accepted_orders: false,
            canceled_orders: false,
            successed_orders: false,
            waiting_list: false
        },
        notification_count_orders: 0,
        notification_count_byuer_orders: 0,
    },
    getters: {
        find_tovars: (state) => state.find_tovars,
        find_tovars_count: (state) => state.find_tovars_count,
        find_filters: (state) => state.find_filters,
        find_offset: (state) => state.find_offset,
        find_limit: (state) => state.find_limit,
        company_contacts: (state) => state.company_contacts,
        company_orders: (state) => state.company_orders,
        buyer_contacts: (state) => state.buyer_contacts,
        order_statuses: (state) => state.order_statuses,
        active_order_filters: (state) => state.active_order_filters,
        notification_count_orders: (state) => state.notification_count_orders,
        notification_count_byuer_orders: (state) => state.notification_count_byuer_orders,
    },
    mutations: {
        SET_FIND_TOVARS(state, playload) {
            state.find_tovars = playload
        },
        SET_FIND_COUNT_TOVARS(state, playload) {
            state.find_tovars_count = playload
        },
        SET_FIND_FILTER_FIELD(state, playload) {
            state.find_filters[playload.field] = playload.value
        },
        SET_FIND_OFFSET(state, playload) {
            state.find_offset = playload
        },
        SET_FIND_LIMIT(state, playload) {
            state.find_limit = playload
        },
        SET_COMPANY_CONTACTS(state, playload) {
            state.company_contacts = playload
        },
        SET_COMPANY_ORDERS(state, playload) {
            state.company_orders = playload
        },
        SET_BUYER_CONTACTS(state, playload) {
            state.buyer_contacts = playload
        },
        SET_ACTIVE_FILTER(state, playload) {
            state.active_order_filters.all_orders = false
            state.active_order_filters.wait_accept_orders = false
            state.active_order_filters.accepted_orders = false
            state.active_order_filters.canceled_orders = false
            state.active_order_filters.successed_orders = false
            state.active_order_filters.waiting_list = false
            state.active_order_filters[playload.field] = playload.value
        },
        SET_NOTIFICATION_COUNT_ORDER(state, playload) {
            state.notification_count_orders = playload
        },
        SET_NOTIFICATION_COUNT_BYUER_ORDER(state, playload) {
            state.notification_count_byuer_orders = playload
        },
        CLEAR_FIND_FILTER(state) {
            state.find_filters = {
                name: '',
                place: 0,
                date: '',
                offset: ''
            }
        },
        CLEAR_FIND_TOVARS(state) {
            state.find_tovars = {}
        }
    },
    actions: {
        setActiveOrderFilter({commit, dispatch}, data) {
            commit('SET_ACTIVE_FILTER', data)
            dispatch('getCompanyOrders')
        },
        setMyOrderFilter({commit, dispatch}, data) {
            commit('SET_ACTIVE_FILTER', data)
            dispatch('getMyOrders')
        },
        setFindOffset({commit}, data) {
            commit('SET_FIND_OFFSET', data)
        },
        setFindFilterField({commit}, data) {
            commit('SET_FIND_OFFSET', 0)
            commit('SET_FIND_FILTER_FIELD', data)
        },
        async getFindTovars({commit, state}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/find/tovars`, {
                name: state.find_filters.name,
                place: state.find_filters.place,
                date: state.find_filters.date,
                offset: state.find_offset
            })
                .then(response => { 
                    commit('SET_FIND_TOVARS', response.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async getFindFavoritesTovars({commit, state}, data) {
            commit('SET_PRELOADER', true)
            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/mainpage/tovars/favorites`, {
                ids: data
            })
                .then(response => { 
                    commit('SET_FIND_TOVARS', response.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async orderTovar({commit, state, dispatch}, data) {
            // проверяем чтобы количество не было 0
            if(data.tovar_count == 0) {
                commit('SET_SUCCESS', {state: true, text: 'Необходимо указать количество', is_error: true})
                return
            }
            //if(data.shipment == 0) {
            //    commit('SET_SUCCESS', {state: true, text: 'Необходимо указать единицы отгрузки', is_error: true})
            //    return
            //}
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/order`, {
                tovar_id: data.tovar_id,
                tovar_count: data.tovar_count,
                shipment: data.shipment,
                is_list: data.is_list
            })
                .then(response => { 
                    commit('SET_PRELOADER', false)
                    commit('SET_SUCCESS', {state: true, text: 'Товар успешно забронирован'})
                    dispatch('getMPTovar', data.tovar_id)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    /*commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: error.response.data.data})*/
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async getMyOrders({commit, state}) {
            commit('SET_FIND_TOVARS', {})
            commit('SET_PRELOADER', true)

            // собираем фильтр
            var filter = -1
            if(state.active_order_filters.all_orders) filter = -1
            if(state.active_order_filters.wait_accept_orders) filter = 0
            if(state.active_order_filters.accepted_orders) filter = 1
            if(state.active_order_filters.canceled_orders) filter = 2
            if(state.active_order_filters.successed_orders) filter = 3
            if(state.active_order_filters.waiting_list) filter = 4

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/my_orders/`+filter)
                .then(response => { 
                    commit('SET_FIND_TOVARS', response.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async getCompanyContacts({commit}, company_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/company_contacts/`+ company_id)
                .then(response => { 
                    commit('SET_COMPANY_CONTACTS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async getCompanyOrders({commit, state}) {
            commit('SET_PRELOADER', true)
            // собираем фильтр
            var filter = -1
            if(state.active_order_filters.all_orders) filter = -1
            if(state.active_order_filters.wait_accept_orders) filter = 0
            if(state.active_order_filters.accepted_orders) filter = 1
            if(state.active_order_filters.canceled_orders) filter = 2
            if(state.active_order_filters.successed_orders) filter = 3
            if(state.active_order_filters.waiting_list) filter = 4


            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/company_orders/`+filter)
                .then(response => { 
                    commit('SET_COMPANY_ORDERS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async getBuyerContacts({commit}, company_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/company_contacts/`+ company_id)
                .then(response => { 
                    commit('SET_COMPANY_CONTACTS', response.data.data)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        async setOrderStatus({commit, dispatch}, data) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/company_orders/`+ data.order_id, {
                status: data.status
            })
                .then(response => { 
                    dispatch('getCompanyOrders')
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_SUCCESS', {state: true, text: error.response.data.data, is_error: true})
                })
        },
        Tovar__AddFavorite({dispatch, commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/favorite`, {
                tovar_id: tovar_id
            })
                .then(response => { 
                    dispatch('getFindTovars')
                    dispatch('MainPageFindTovars')
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        Tovar__DeleteFavorite({dispatch, commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.delete(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/favorite/`+tovar_id)
                .then(response => { 
                    dispatch('getFindTovars')
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        Tovar__DeleteFromFavorite({dispatch, commit}, tovar_id) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.delete(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/favorite/`+tovar_id)
                .then(response => { 
                    dispatch('getFindFavoritesTovars')
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async MainPageFindTovars({commit, state}) {
            commit('SET_PRELOADER', true)
            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/mainpage/tovars`, {
                name: state.find_filters.name,
                place: state.find_filters.place,
                date: state.find_filters.date,
                offset: state.find_offset*state.find_limit,
                limit: state.find_limit
            })
                .then(response => { 
                    commit('SET_FIND_TOVARS', response.data)
                    commit('SET_FIND_COUNT_TOVARS', response.data.count)
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                })
        },
        async Tovar__NotificationWaitingOrders({dispatch, commit}) {
            //console.log(localStorage.getItem('token'))
            if(localStorage.getItem('token') == null || !localStorage.getItem('token'))
                return
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/tovars/tovar/notification/waiting_orders`)
                .then(response => { 
                    //dispatch('getFindFavoritesTovars')
                    commit('SET_PRELOADER', false)
                    commit('SET_NOTIFICATION_COUNT_ORDER', response.data.data.count)
                    commit('SET_NOTIFICATION_COUNT_BYUER_ORDER', response.data.byuer_orders.count)
                })
                .catch(error => { 
                    commit('SET_PRELOADER', false)
                    console.log(error) 
                    //commit('SET_ERROR', {field: 'state', value: true})
                    //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
                    //commit('SET_ERROR', {field: 'is_error', value: true})
                })
        },
    }
}