<template>
    <div class="main-top-preloader" v-if="preloader">
        <div class="main-top-preloader--progress-bar" :style="calculated_width"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'MainTopPreloader',
    data() {
        return {
            calculated_width: 'width: 0%'
        } 
    },
    computed: {
        ...mapGetters(['preloader'])
    },
    watch: {
        preloader: function(newVal) {
            if(newVal)
                this.startBar()
        }
    },
    methods: {
        startBar() {
            console.log('start progress bar')
            for(var i = 0; i<101; i++) {
                setTimeout(() => { this.calculated_width = 'width: ' + i + '%'}, 1)
                console.log(this.calculated_width)
            }
        }
    },
    mounted() {
        this.startBar()
    }
}
</script>

<style lang="scss" scoped>
    .main-top-preloader {
        display: flex;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 5px;
        background: #fff;
        border-bottom: 1px solid #ccc;
        &--progress-bar {
            display: flex;
            height: 100%;
            background: rgba($color: blue, $alpha: 0.3);
        }
    }
</style>