<template>
  <!--<main-top-preloader />-->
  <common-success />
  <user-access-blocked />
  <router-view/>
</template>

<script>
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(97728371, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });

   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WPKLPH5S');



import mainTopPreloader from '@/components/preloader/mainTopPreloader.vue';
import CommonSuccess from './components/messages/CommonSuccess.vue';
import UserAccessBlocked from './components/messages/UserAccessBlocked.vue';
import { mapActions } from 'vuex';
export default {
  name: 'App',
  components: {mainTopPreloader, CommonSuccess, UserAccessBlocked},
  methods: {
    ...mapActions(['getStatistics'])
  },
  created() {
    this.getStatistics()

  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

html, body {
  display: flex;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
  margin: 0px;
  padding: 0px;
  
}
#app {
  display: flex;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  
}
</style>
