<template>
    <div :class="{
            'base-button': true, 
            'width-fit' : widthFit,
            'width-full': widthFull,
            'disabled': isDisable
        }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        widthFit: {
            type: Boolean,
            default: false
        },
        widthFull: {
            type: Boolean,
            default: false
        },
        isDisable: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .disabled {
        background: #eaeaea !important;
        cursor: not-allowed !important;
        &:hover {
            box-shadow: none !important;
        }
    }
    .base-button {
        display: flex;
        height: 36px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 6px;
        background: var(--blue-blue-500, #58F);
        box-sizing: border-box;
        color: var(--text-text-white, #FFF);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: 0.14px;
        align-content: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.30);
            font-weight: 500;
        }
    }

    .width-fit {
        width: fit-content;
    }
    .width-full {
        width: 100%;
    }
</style>