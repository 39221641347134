import { createRouter, createWebHistory } from 'vue-router'
import Signup from './routes/signup'
import Login from './routes/login'
import Account from './routes/account'
import MyTovars from './routes/myTovars'
import findAndOrder from './routes/findAndOrder'
import activeOrders from './routes/activeOrders'
import myOrders from './routes/myOrders'
import Admin from './routes/admin'
import V2 from './routes/v2/index'

const routes = [
  ...Admin,
  /*{
    path: '/',
    name: 'IndexView',
    component: function () {
      return import('../views/IndexView.vue')
    }
  },*/
  /*{
    path: '/',
    name: 'IndexDemoView',
    component: function () {
      return import('../views/IndexDemoView.vue')
    }
  },
  {
    path: '/contacts',
    name: 'ContactsView',
    component: function () {
      return import('../views/ContactsView.vue')
    }
  },
  {
    path: '/system',
    name: 'EnterPoint',
    props: true,
    component: function () {
      return import('../views/system/EnterPoint.vue')
    },
    children: [
      ...Account,
      ...MyTovars,
      ...findAndOrder,
      ...activeOrders,
      ...myOrders
    ],
  },
  */
  {
    path: '/',
    name: 'V2EnterPoint',
    props: true,
    component: function () {
      return import('../views/v2/V2Container.vue')
    },
    children: [
      ...V2,
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
