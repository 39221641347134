<template>
    <div class="base-link">
        <slot></slot>
    </div>
</template>


<script>
export default {
    name: 'BaseLink'
}
</script>

<style lang="scss" scoped>
    .base-link {
        display: flex;
        color: #237BFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        letter-spacing: 0.14px;
        cursor: pointer;
        align-content: center;
        align-items: center;
        gap: 5px;
        &:hover {
            text-decoration: underline;
        }
    }
</style>