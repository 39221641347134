import { createStore } from 'vuex'
import Signup from './modules/signup'
import Login from './modules/login'
import CommonUser from './modules/user'
import Account from './modules/account'
import Tovar from './modules/tovar'
import FindTovars from './modules/findTovars'
import AdminUser from './admin/user'
import AdminRefTovars from './admin/refbook'
import AdminTovars from './admin/tovars'
//import Favorite from './modules/favorite'
import createPersistedState from 'vuex-persistedstate';
import axios from "axios"



export default createStore({
  modules: {
    Signup, Login, CommonUser, Account, Tovar, FindTovars, AdminUser, AdminRefTovars, AdminTovars
  },
  state: {
    preloader: false,
    is_blocked: 0,
    error: {
      state: false,
      text: 'Текст ошибки для теста длинный'
    },
    modals: {
      modal_edit_location: false,
      modal_edit_date: false,
      modal_edit_quantity: false,
      modal_edit_transit: false,
      modal_edit_price: false,
      modal_edit_count_in_pack: false,
      modal_edit_ref_tovar: false,
      modal_filter_place: false,
      modal_find_filter_place: false,
      modal_common_dialog: false,
      modal_company_contacts: false,
      modal_admin_ref_tovar: false,
      modal_history_cart: false,
      modal_admin_user_edit: false,
     
    },
    common_modal_context: {
      context: '',
      action: ''
    },
    success_window: {
      state: false,
      text: '',
      is_error: false
    },
    access_blocked_msg: false,
    main_page: {
      grid_tovars_mode: 1 // 1 - сетка, 2 - список
    },
    favorites: [],
    notifications: {},
    notification_statues: {
        all: true,
        not_read: false,
        read: false
    }
  },
  getters: {
    preloader: (state) => state.preloader,
    error: (state) => state.error,
    modals: (state) => state.modals,
    common_modal_context: (state) => state.common_modal_context,
    success_window: (state) => state.success_window,
    access_blocked_msg: (state) => state.access_blocked_msg,
    main_page: (state) => state.main_page,
    favorites: (state) => state.favorites,
    notifications: (state) => state.notifications,
    notification_statues: (state) => state.notification_statues,
    is_blocked: (state) => state.is_blocked,
  },
  mutations: {
    SET_BLOCKED(state, playload) {
      state.is_blocked = playload
    },
    SET_PRELOADER(state, playload) {
      state.preloader = playload
    },
    SET_ERROR(state, playload) {
      state.error[playload.field] = playload.value
    },
    SET_MODAL(state, playload) {
      state.modals[playload.field] = playload.value
    },
    SET_COMMON_MODAL_CONTEXT(state, playload) {
      state.common_modal_context = playload
    },
    SET_SUCCESS(state, playload) {
      state.success_window = playload
    },
    SET_ACCESS_BLOCKED_MESSAGE(state, playload) {
      state.access_blocked_msg = playload
    },
    SET_MAIN_PAGE_FIELD(state, playload) {
      state.main_page[playload.field] = playload.value
    },
    ADD_TO_FAVORITE(state, playload) {
      state.favorites.push({ id: playload})
    },
    REMOVE_FROM_FAVORITE(state, playload) {
      //state.favorites.push({ id: playload})
      var _item = 0
      for(let item in state.favorites) {
        if(state.favorites[item].id == playload)
          _item = item
      }
      state.favorites.splice(_item, 1)
    },
    SET_NOTIFICATION(state, playload) {
      state.notifications = playload
    },
    SET_NOTIFICATION_STATUS(state, playload) {
            state.notification_statues.all = false
            state.notification_statues.not_read = false
            state.notification_statues.read = false

            state.notification_statues[playload] = true
    }
  },
  actions: {
    setSuccess({commit}, data) {
      commit('SET_SUCCESS', data)
    },
    setPreloader({commit}, data) {
      commit('SET_PRELOADER', data)
    },
    setError({commit}, data) {
      commit('SET_ERROR', data)
    },
    setModal({commit}, data) {
      console.log(data)
      commit('SET_MODAL', data)
    },
    setCommonModalContext({commit}, data) {
      commit('SET_COMMON_MODAL_CONTEXT', data)
    },
    async GET__Notifications({commit, state}, data) {
      commit('SET_PRELOADER', true)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
      await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/notifications`, {
          status: data
      })
          .then(response => { 
              commit('SET_NOTIFICATION', response.data.data)
              commit('SET_PRELOADER', false)
          })
          .catch(error => { 
              commit('SET_PRELOADER', false)
              console.log(error) 
              //commit('SET_ERROR', {field: 'state', value: true})
              //commit('SET_ERROR', {field: 'text', value: error.response.data.data})
          })
    },
    async SET__ReadNotification({commit, dispatch, state}, data) {
      commit('SET_PRELOADER', true)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
      await axios.post(`${process.env.VUE_APP_API_URL}/api/tovars/notification`, {
          status: data.status,
          id: data.id
      })
          .then(response => { 
              //commit('SET_NOTIFICATION', response.data.data)
              // проверяем какой установлен статус в шапке
              var status = -1
              if(state.notification_statues.all) status = -1
              if(state.notification_statues.not_read) status = 0
              if(state.notification_statues.read) status = 1

              dispatch('GET__Notifications', status)
              commit('SET_PRELOADER', false)
          })
          .catch(error => { 
              commit('SET_PRELOADER', false)
              console.log(error) 
          })
    },
  },
  plugins: [
    createPersistedState({paths: ['favorites']})
  ]
  /**
   * 
   * {
        
    }
   */
})
