import axios from "axios"

export default {
    state: {
        common_user: {
            id: 0,
            email: '',
            name: ' ',
            surname: ' ',
            thirdname: '',
            phone: '',
            role: 'ROLE_SALLER',
            firstLogin: 0,
            company_id: 0,
        },
        common_user_old: {
            id: 0,
            email: '',
            name: ' ',
            surname: ' ',
            thirdname: '',
            phone: '',
            role: 'ROLE_SALLER',
            firstLogin: 0,
            company_id: 0,
        },
        count_users: 0,
        count_orders: 0,
        isRedirect: false
    },
    getters: {
        common_user: (state) => state.common_user,
        common_user_old: (state) => state.common_user_old,
        isRedirect: (state) => state.isRedirect,
        count_users: (state) => state.count_users,
        count_orders: (state) => state.count_orders,
    },
    mutations: {
        SET_COMMOM_USER(state, playload) {
            state.common_user = playload
            state.common_user_old.id = playload.id
            state.common_user_old.email = playload.email
            state.common_user_old.name = playload.name
            state.common_user_old.surname = playload.surname
            state.common_user_old.thirdname = playload.thirdname
            state.common_user_old.phone = playload.phone
            state.common_user_old.role = playload.role
            state.common_user_old.firstLogin = playload.firstLogin
            state.common_user_old.company_id = playload.company_id
        },
        SET_REDIRECT(state, playload) {
            state.isRedirect = playload
        },
        SET_COMMON_USER_FIELD(state, playload) {
            state.common_user[playload.field] = playload.value
        },
        SET_COUNT_USERS(state, playload) {
            state.count_users = playload
        },
        SET_COUNT_ORDERS(state, playload) {
            state.count_orders = playload
        }
    },
    actions: {
        setCommonUserField({commit}, data) {
            commit('SET_COMMON_USER_FIELD', data)
        },
        async getStatistics({commit}) {
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/data/info`)
                .then(response => {
                    commit('SET_COUNT_USERS', response.data.data.count)
                })
                .catch(error => { 
                    console.log(error)
                    commit('SET_PRELOADER', false)
                })
        },
        async getUserData({commit}) {
            if(localStorage.getItem('token') == null || !localStorage.getItem('token'))
                return
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.get(`${process.env.VUE_APP_API_URL}/api/profile/get`)
                .then(response => {
                    commit('SET_COMMOM_USER', response.data.data)
                    //commit('SET_COMMOM_USER_OLD', response.data.data)
                    commit('SET_COUNT_USERS', response.data.count_users.count)
                    commit('SET_COUNT_ORDERS', response.data.count_orders.count)
                    localStorage.setItem('name', response.data.data.name)
                    localStorage.setItem('surname', response.data.data.surname)
                    commit('SET_PRELOADER', false)
                    if(response.data.data.firstLogin == 0)
                        commit('SET_REDIRECT', true)
                    else 
                        commit('SET_REDIRECT', false)
                })
                .catch(error => { 
                    console.log(error)
                    commit('SET_PRELOADER', false)
                })
        },
        async updateData({dispatch, commit, state}) {
            for(let item in state.common_user) {
                console.log(item)
                if(state.common_user[item].length == 0) {
                    commit('SET_ERROR', {field: 'state', value: true})
                    commit('SET_ERROR', {field: 'text', value: 'Все поля обязательны для заполнения'})
                    return
                }

            }
            commit('SET_ERROR', {field: 'state', value: false})
            commit('SET_PRELOADER', true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/profile/update`, state.common_user)
                .then(response => {
                    dispatch('getUserData')
                    commit('SET_PRELOADER', false)
                })
                .catch(error => { 
                    console.log(error)
                    commit('SET_PRELOADER', false)
                })
        },
        
    }
}