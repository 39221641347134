import axios from "axios"


export default {
    state: {
        user: {
            email: '',
            name: '',
            role: '',
            password: '',
            confirm_password: '',
            code: ''
        },
        success: {
            step_one: false,
            step_two: false,
        },
        errorSignup: {
            state: false,
            text: ''
        }
    },
    getters: {
        user: (state) => state.user,
        errorSignup: (state) => state.errorSignup,
        successSignupStepOne: (state) => state.success.step_one,
        successSignupStepTwo: (state) => state.success.step_two,
    },
    mutations: {
        SET_USER_FIELD(state, playload) {
            state.user[playload.field] = playload.value
        },
        SET_ERROR(state, playload) {
            state.errorSignup = playload
        },
        SET_SUCCESS(state, playload) {
            state.success[playload] = true
        }
    },
    actions: {
        setSignupUserField({commit}, data) {
            commit('SET_USER_FIELD', data)
        },
        clearError({commit}) {
            commit('SET_ERROR', {state: false, text: ''})
        },
        signup({commit, state}) {
            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/signup`, state.user)
                .then(response => {
                    commit('SET_SUCCESS', 'step_one')
                })
                .catch(error => { 
                    console.log(error)
                    //commit('SET_ERROR', {
                    //    state: true,
                    //    text: error.response.data.data
                    //})
                })
        },
        signup_v2({commit, state}, data) {
            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            axios.post(`${process.env.VUE_APP_API_URL}/api/signup`, data)
                .then(response => {
                    commit('SET_SUCCESS', 'step_one')
                })
                .catch(error => { 
                    console.log(error)
                    //commit('SET_ERROR', {
                    //    state: true,
                    //    text: error.response.data.data
                    //})
                })
        },
        setPassword({commit, state}, data) {
            let user = {
                code: data.code,
                email: data.email,
                password: data.password,
                confirm_password: data.confirm_password
            }
            axios.post(`${process.env.VUE_APP_API_URL}/api/set_password`, user)
                .then(response => {
                    // устанавливаем токен
                    localStorage.setItem('token', response.data.token)
                    commit('SET_USER_FIELD', {field: 'role', value: response.data.body.role})
                    commit('SET_SUCCESS', 'step_two')
                })
                .catch(error => { 
                    console.log(error)
                    //commit('SET_ERROR', {
                    //    state: true,
                    //    text: error.response.data.data
                    //})
                })
        } 
    }
}