import axios from "axios"


export default {
    state: {
        login_user: {
            email: '',
            password: ''
        },
        login_success: false,
        error_login: {
            state: false,
            text: ''
        },
        login_window: false
    },
    getters: {
        login_user: (state) => state.login_user,
        login_success: (state) => state.login_success,
        error_login: (state) => state.error_login,
        login_window: (state) => state.login_window,
    },
    mutations: {
        SET_USER_LOGIN_FIELD(state, playload) {
            state.login_user[playload.field] = playload.value
        },
        SET_LOGIN_SUCCESS(state, playload) {
            state.login_success = playload
        },
        SET_LOGIN_ERROR(state, playload) {
            state.error_login = playload
        },
        SET_LOGIN_WINDOW(state, playload) {
            state.login_window = playload
        }
    },
    actions: {
        setLoginUserField({commit}, data) {
            commit('SET_USER_LOGIN_FIELD', data)
        },
        clearLoginError({commit}) {
            commit('SET_LOGIN_ERROR', {state: false, text: ''})
        },
        async login({commit, state}) {
            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            await axios.post(`${process.env.VUE_APP_API_URL}/api/login`, state.login_user)
                .then(response => {
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('role', response.data.body.role)
                    localStorage.setItem('username', response.data.body.name)
                    localStorage.setItem('company_id', response.data.body.company_id)
                    commit('SET_LOGIN_SUCCESS', true)
                })
                .catch(error => { 
                    console.log(error)
                    commit('SET_LOGIN_ERROR', {
                        state: true,
                        text: error.data.data
                    })
                })
        },
    }
}